import React, { useEffect, useState } from "react";
import request from "superagent";
import bathTemp from "../static/bathTemp.svg";

const months = [
  "jan",
  "feb",
  "mar",
  "apr",
  "mai",
  "jun",
  "jul",
  "aug",
  "sep",
  "okt",
  "nov",
  "des",
];

function useBadeTemp(id) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    setLoading(true);
    setData(undefined);

    request
      .get(
        "https://water-temp.prod.front.bkk.no/api/v1/temperatures/" + id + ""
      )
      .then((res) => res.body)
      .then((res) => {
        if (res.temperature !== null) {
          const data = {
            ...res,
            date: new Date(res.time),
          };

          setData(data);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, [id]);

  return { data, loading };
}

const BadeTempBkk = ({ id }) => {
  const { data } = useBadeTemp(id);
  return data ? (
    <div
      className={[
        "badetemp-container",
        data.date < new Date().setDate(new Date().getDate() - 7)
          ? "hidden"
          : "",
      ].join(" ")}
    >
      <div className="temp-icon">
        <img alt="badetemp" src={bathTemp} />
      </div>
      <div className="temp-info">
        <span className="celcius">
          {`${data.temperature}˚`}
          <span>c</span>
        </span>
        <span className="date">{`${data.date.getDate()}.${
          months[data.date.getMonth()]
        } kl ${data.date.getHours()}.${
          data.date.getMinutes().toString().length === 1
            ? "0" + data.date.getMinutes()
            : data.date.getMinutes()
        }`}</span>
      </div>
    </div>
  ) : null;
};

export default BadeTempBkk;
