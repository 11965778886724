import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";
import "./Footer.scss";

class Footer extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div className="footer--container">
        <span>
          <a href="mailto:vestkystparken@bof.no">{t("contact")}</a>
        </span>
        |
        <span>
          <a
            href="https://www.friluftsrad.no/om-fl/forvaltningslosning/samtykke"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("privacy")}
          </a>
        </span>
        |
        <span>
          {"   "}
          {t("developedBy")}
          <a
            href="https://avinet.no/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("avinet")}
          </a>
        </span>
      </div>
    );
  }
}

Footer.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate("Footer")(Footer);
