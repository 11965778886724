import Flickr from "flickr-sdk";

var flickr;
var userId;
const baseUrl =
  "https://farm{farm-id}.staticflickr.com/{server-id}/{id}_{secret}.jpg";

export const loadPrimaryPhotoUrlFromSet = async (photosetUrl) => {
  if (photosetUrl.endsWith("/")) {
    photosetUrl = photosetUrl.slice(0, -1);
  }
  const photoSetId = photosetUrl.slice(
    photosetUrl.lastIndexOf("/") + 1,
    photosetUrl.length
  );

  if (isNaN(photoSetId)) {
    return false;
  }

  try {
    var res = await flickr.photosets.getPhotos({
      photoset_id: photoSetId,
      user_id: userId,
    });
    res = JSON.parse(res.text);
    const primary = res.photoset.primary;
    const photos = res.photoset.photo;
    return photos.find((p) => p.id === primary);
  } catch (ex) {
    return false;
  }
};

export const buildStaticFlickrUrl = (flickrObject) => {
  const { farm, server, id, secret } = flickrObject;
  var url = baseUrl
    .replace("{farm-id}", farm)
    .replace("{server-id}", server)
    .replace("{id}", id)
    .replace("{secret}", secret);
  return url;
};

export default function init(apiKey, flickrUserId) {
  flickr = new Flickr(apiKey);
  userId = flickrUserId;
}
