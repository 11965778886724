import React, { useEffect, useState } from "react";
import request from "superagent";
import config from "../config";

const cache = {};

function useVimeo(id) {
  const [vimeo, setVimeo] = useState();
  useEffect(() => {
    setVimeo(null);
    if (!id) return;

    if (cache[id]) {
      setVimeo(cache[id]);
      return;
    }

    request
      .get(config.adaptiveUrl + "proxies/xVimeoEmbed.ashx?url=" + id)
      .then((res) => res.body)
      .then((res) => {
        cache[id] = res;
        setVimeo(res);
      });
  }, [id]);
  return vimeo;
}

const VimeoVideo = ({ id }) => {
  const vimeo = useVimeo(id);

  return vimeo ? (
    <div
      className="article--vimeo"
      style={{
        paddingBottom: (100 * vimeo.height) / vimeo.width + "%",
      }}
    >
      <iframe
        title={vimeo.title || vimeo.video_id}
        src={"https://player.vimeo.com/video/" + vimeo.video_id}
        frameBorder={0}
        allowFullScreen
      />
    </div>
  ) : null;
};

export default VimeoVideo;
