import React from "react";
import { Link as ReactLink } from "react-router-dom";
import PropTypes from "prop-types";

class Link extends React.Component {
  isExternal(uri) {
    return uri.startsWith("http://") || uri.startsWith("https://");
  }

  prepareUri(uri) {
    if (this.isExternal(uri)) {
      return uri;
    } else if (uri.startsWith("/")) {
      return uri;
    }
    return "/" + uri;
  }

  render() {
    const { to, title, ...rest } = this.props;
    return this.isExternal(to) ? (
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      <a
        href={to}
        title="Avansert kartløsning tilpasset nettbrett/PC"
        target="_blank"
        rel="noopener noreferrer"
        {...this.props}
      />
    ) : (
      <ReactLink to={this.prepareUri(to)} {...rest} />
    );
  }
}

Link.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string,
  onClick: PropTypes.func,
};

export default Link;
