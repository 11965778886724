import React from "react";
import PropTypes from "prop-types";
import Icon from "./Icon.js";
import "./Switch.scss";

const Switch = (props) => {
  const { onChange, checked, disabled } = props;
  // debugger;
  return (
    <label className={disabled ? "switch no-pointer-events" : "switch"}>
      <input
        className="checkbox-label"
        type="checkbox"
        onChange={() => onChange(!checked)}
        checked={checked}
      />
      <div className={checked ? "checkmark-active" : "checkmark-inactive"}>
        <Icon name="checkmark" />
      </div>
    </label>
  );
};

Switch.defaultProps = {
  disabled: false,
};

Switch.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

export default Switch;
