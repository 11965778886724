import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";
import { Link } from "react-router-dom";
import KayakImg from "../../static/frontpage/kayak_img.jpg";
import "./OutportInfo.scss";

class OutportInfo extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div className="outport-container">
        <img src={KayakImg} alt="" />
        <div className="outport-text-container">
          <h4 className="title">{t("title")}</h4>
          <p className="description">{t("description")}</p>
          <Link
            to="/alleturer/vestkystparkelementer?vestkystparkelementer__type=1,2,4,6,5,24"
            className="btn btn-primary"
          >
            {t("map")}
          </Link>
        </div>
      </div>
    );
  }
}

OutportInfo.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate("OutportInfo")(OutportInfo);
