import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";
import { Link } from "react-router-dom";
import checkcircle from "../../static/frontpage/checkcircle.svg";
import "./AboutPreview.scss";

class AboutPreview extends React.Component {
  render() {
    const { t } = this.props;
    const listValues = [
      t("nature"),
      t("biology"),
      t("natureGiven"),
      t("esthetics"),
      t("health"),
    ];
    return (
      <span className="about-preview-span">
        <div className="about-preview-container">
          <div className="list-title-container">
            <h3 className="title">{t("title")}</h3>
            {listValues.map((content, i) => {
              return (
                <span className="list-element-span" key={i}>
                  <img src={checkcircle} className="checkmark" alt="" />
                  <h4 className="list-element-text">{content}</h4>
                </span>
              );
            })}
          </div>
          <Link to="/omvestkystparken" className="btn btn-primary">
            {t("about")}
          </Link>
        </div>

        <div className="img-content">
          <div className="img-div--infoarboretet" />
          <div className="img-div--turveihellenes" />
          <div className="img-div--fiskeandkollevaag" />
        </div>
      </span>
    );
  }
}

AboutPreview.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate("AboutPreview")(AboutPreview);
