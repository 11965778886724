import React from "react";
import "./FrontPage/ContactUs.scss";

class ContactComponent extends React.Component {
  componentDidUpdate() {}
  render() {
    return (
      <div className="god-tur--page " /**style='background-color: #f5f4f2'**/>
        <div className="god-tur--cms" /**style='background-color: #f5f4f2'**/>
          <div
            className="contact-vkp-page" /**style='background-color: #f5f4f2'**/
          >
            <h1>Kontakt oss</h1>
            <div className="contact-content">
              <div className="content phone">
                <img
                  src="https://friluftsforvaltning.avinet.no/WebServices/generic/Media.asmx/Download?uuid=5156051b-7ac7-41b8-b1d5-177941908274"
                  width="50px"
                  alt=""
                />
                <h1>Telefon</h1>
                <p>
                  {window.gtag("config", "AW-650761050/oRpnCJHsw80BENqmp7YC", {
                    phone_conversion_number: "55392950",
                  })}
                  <a
                    href="tel:55392950"
                    onClick={window.gtag(
                      "config",
                      "AW-650761050/oRpnCJHsw80BENqmp7YC",
                      {
                        phone_conversion_number: "55392950",
                      }
                    )}
                  >
                    55 39 29 50
                  </a>
                </p>
              </div>

              <div className="content email">
                <img
                  src="https://friluftsforvaltning.avinet.no/WebServices/generic/Media.asmx/Download?uuid=4a23e3a8-930e-4630-9cb6-e25861419d7c"
                  width="50px"
                  alt=""
                />
                <h1>E-post</h1>
                <a href="mailto:vestkystparken@bof.no">vestkystparken@bof.no</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ContactComponent;
