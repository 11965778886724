/** @format */

import React from "react";
import PropTypes from "prop-types";
import fottur from "../../static/defaultImages/fottur.jpg";
import fisketur from "../../static/defaultImages/fisketur.jpg";
import padletur from "../../static/defaultImages/padletur.jpg";
import sykkeltur from "../../static/defaultImages/sykkeltur.jpg";
import baatutsetting from "../../static/defaultImages/baatutsetting.jpg";
import badeplass from "../../static/defaultImages/badeplass.jpg";
import gapahuk from "../../static/defaultImages/gapahuk.jpg";
import rasteplass from "../../static/defaultImages/rasteplass.jpg";
import flytebrygge from "../../static/defaultImages/flytebrygge.png";
import defaultImage from "../../static/defaultImages/defaultImage.jpeg";
import friluftsomraade from "../../static/defaultImages/friluftsomraade.png";
import dykking from "../../static/defaultImages/dykking.png";
import ankring from "../../static/defaultImages/ankring.png";
import baal from "../../static/defaultImages/baal.png";
import lek from "../../static/defaultImages/lek.png";
import parkering from "../../static/defaultImages/parkering.png";
import drikkevann from "../../static/defaultImages/drikkevann.png";
import fortoyningsbolter from "../../static/defaultImages/fortoyningsbolter.png";
import klatre from "../../static/defaultImages/klatre.png";
import boye from "../../static/defaultImages/boye.png";
import toalett from "../../static/defaultImages/toalett.png";
import teltplass from "../../static/defaultImages/teltplass.png";
import kai from "../../static/defaultImages/kai.png";
import tellturLogo from "../../static/logo_telltur.png";
import {
  buildStaticFlickrUrl,
  loadPrimaryPhotoUrlFromSet,
} from "../../utils/flickr";
import "./GodTurArticleListComponent.scss";

class GodTurArticleListComponent extends React.Component {
  constructor(props) {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.loadFlickrImage();
  }

  async loadFlickrImage() {
    const { article } = this.props;
    if (article.properties.flickr_url) {
      const flickrObject = await loadPrimaryPhotoUrlFromSet(
        article.properties.flickr_url
      );
      this.setState({ flickrObject });
    }
  }

  // eslint-disable-next-line getter-return
  get getFlickrImageUrl() {
    const { flickrObject } = this.state;
    if (flickrObject) {
      return buildStaticFlickrUrl(flickrObject);
    }
  }

  get defaultImage() {
    const {
      article: {
        // eslint-disable-next-line
        properties: { kategori_array, datakilde /**kategori**/ },
      },
    } = this.props;
    // eslint-disable-next-line

    if (datakilde === 2) {
      return friluftsomraade;
    }

    switch (kategori_array[0]) {
      case 0:
        return friluftsomraade;
      case 1:
        return flytebrygge;
      case 2:
        return kai;
      case 3:
        return badeplass;
      case 4:
        return fortoyningsbolter;
      case 5:
        return baatutsetting;
      case 6:
        return boye;
      case 7:
        return drikkevann;
      case 9:
        return toalett;
      case 10:
        return padletur;
      case 11:
        return fisketur;
      case 12:
        return dykking;
      case 13:
        return baal;
      case 14:
        return lek;
      case 15:
        return klatre;
      case 16:
        return sykkeltur;
      case 17:
        return fottur;
      case 21:
        return gapahuk;
      case 22:
        return parkering;
      case 23:
        return rasteplass;
      case 24:
        return ankring;
      case 26:
        return teltplass;
      default:
        return defaultImage;
    }
  }

  render() {
    const {
      onArticleOpen,
      thumbnailClassName,
      thumbnail,
      article,
      // eslint-disable-next-line
      article: { statskog_url: statskogUrl },
      loadImage,
    } = this.props;
    const flickrImage = this.getFlickrImageUrl;

    const thumbnailProp =
      (article.properties.id.startsWith("05") ||
        article.properties.id.startsWith("06")) &&
      article.properties.thumbnail
        ? `https://bof.avinet.no/WebServices/generic/Media.asmx/Download?uuid=${article.properties.thumbnail}&thumbnail_size=small`
        : thumbnail;

    return (
      <div
        className={
          thumbnailClassName +
          (thumbnail
            ? " articleList--withThumbnail"
            : " articleList--withDefaultThumbnail") +
          " articleList--entry clearfix"
        }
        onMouseOver={() => article.olFeature.set("hover", true)}
        onMouseOut={() => article.olFeature.set("hover", false)}
        onClick={() =>
          article.properties.id_telltur
            ? window.open(
                `https://telltur.no/turmaal/${article.properties.kat_telltur}/${article.properties.id_telltur}`,
                "_blank"
              )
            : onArticleOpen(article)
        }
      >
        <div className="thumbnailWrapper">
          <div className="thumbnail-type-title">
            {article.properties.type_friluftselement && (
              <p>
                <b>{article.properties.type_friluftselement}</b>
              </p>
            )}
          </div>
          {loadImage ? (
            <img
              data-original={flickrImage || statskogUrl || thumbnailProp}
              src={
                flickrImage || statskogUrl || thumbnailProp || this.defaultImage
              }
              className="loaded"
              alt=""
            />
          ) : (
            <img
              data-original={flickrImage || statskogUrl || thumbnailProp}
              alt=""
            />
          )}
          {article.properties.id_telltur && (
            <span className="thumbnail--badge">
              <img src={tellturLogo} alt="" />
            </span>
          )}
        </div>

        <div className="thumbnail-description">
          {/* <div className="btn-bg">
            <button
              className="read-more-btn"
              onClick={() =>
                article.properties.id_telltur
                  ? window.open(
                      `https://telltur.no/turmaal/${article.properties.kat_telltur}/${article.properties.id_telltur}`,
                      "_blank"
                    )
                  : onArticleOpen(article)
              }
            >
              Les Mer
            </button>
          </div> */}
          {article.properties.title && <h4>{`${article.properties.title}`}</h4>}

          {article.properties && article.properties.content && (
            <p
              dangerouslySetInnerHTML={{
                __html: `${article.properties.content}`,
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

GodTurArticleListComponent.propTypes = {
  onArticleOpen: PropTypes.func.isRequired,
  thumbnailClassName: PropTypes.string.isRequired,
  thumbnail: PropTypes.string,
  article: PropTypes.object.isRequired,
  loadImage: PropTypes.bool,
};

export default GodTurArticleListComponent;
