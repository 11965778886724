/**
 * Debounce utility. Takes a callback function that shall be called
 * after the given delay. Multiple calls resets the timeout.
 *
 * Used to debounce (buffer) event callbacks to avoid handling
 * multiple events of the same time too often.
 *
 * @param{function} func Callback function
 * @param{int} [delay] Delay in milliseconds, defaults to 250
 * @param{bool} [immediate] If set, func is called on delay start instead of end
 * @return{function} Function wrapping the callback
 */
export default function (func, delay, immediate) {
  var timeout;
  return function () {
    const scope = this;
    const args = arguments;
    const trigger = () => {
      timeout = undefined;
      if (!immediate) func.apply(scope, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(trigger, delay || 250);
    if (callNow) func.apply(scope, args);
  };
}
