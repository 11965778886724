export function findType(symbol) {
  switch (symbol) {
    case 4:
      return "Badeflåte";
    case 5:
      return "Badeplass";
    case 6:
      return "Badeplass";
    case 8:
      return "Balløkke";
    case 9:
      return "basseng";
    case 13:
      return "Bål-/grillplass";
    case 14:
      return "Båtutsettingsrampe";
    case 17:
      return "Fottur";
    case 18:
      return "Utsetting Kano/Kajakk";
    case 20:
      return "Ferskvann";
    case 21:
      return "Fiske";
    case 22:
      return "Flytebrygge";
    case 24:
      return "Fortøyningsbolter";
    case 25:
      return "Fortoyningsboye";
    case 29:
      return "Gapahuk";
    case 33:
      return "Grillhytte";
    case 37:
      return "Aktivitetsanlegg";
    case 40:
      return "Kai";
    case 42:
      return "Klatring";
    case 47:
      return "Lekeplass";
    case 48:
      return "Lekeplass";
    case 54:
      return "Parkeringsplass med ladestasjon";
    case 55:
      return "Parkeringsplass uten ladestasjon";
    case 56:
      return "Rasteplass";
    case 66:
      return "Stupebrett";
    case 67:
      return "Teltplass";
    case 68:
      return "Toalett";
    case 70:
      return "Utedo";
    case 71:
      return "Ankring";
    case 277:
      return "Padletur";
    case 281:
      return "Sykkeltur";
    case 282:
      return "Fottur";
    case 111:
      return "Naust";
    case 555:
      return "Hytte";
    case 444:
      return "Hytte";
    default:
      return "Friluftsområde";
  }
}
