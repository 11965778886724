import React from "react";
import PropTypes from "prop-types";
import "./BannerContent.scss";
import { translate } from "react-translate";
import { Link } from "react-router-dom";
import Icon from "../Icon";

class BannerContent extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <main className="banner-wave-bg">
        <h1 className="title">{t("title")}</h1>
        <p>{t("description")}</p>
        <Link to="/alleturer/vestkystparkelementer" className="btn btn-primary">
          <Icon name="map" className="map-icon" />
          {t("map")}
        </Link>
      </main>
    );
  }
}

BannerContent.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate("BannerContent")(BannerContent);
