import React, { useState, useEffect, useCallback } from "react";
import "./Search.scss";
import LoadingIndicator from "../../components/LoadingIndicator";
import request from "superagent";
import SearchResultComponent from "../../components/SearchResultComponent";
import debounce from "../../utils/debounce";

const Search = ({ className, onResultSelected, clearButtonIcon }) => {
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [data, setData] = useState([]);

  const onQueryChange = useCallback((query) => {
    setLoading(true);
    setQuery(query);
  }, []);

  const onClearSearch = useCallback(() => {
    setQuery("");
    setData([]);
  }, []);

  useEffect(() => {
    const search = debounce(() => {
      setLoading(true);
      setData(undefined);
      if (query === undefined || query === null || query === "") {
        setLoading(false);
        return;
      }

      request
        .get(
          "https://api.kartverket.no/stedsnavn/v1/sted?sok=" +
            query +
            "*" +
            "&utkoordsys=3857&treffPerSide=30&side=1" +
            "&fnr=11" +
            "&fnr=46" +
            "&fuzzy=true"
        )
        .then((res) => res.body)
        .then((res) => {
          if (res) {
            setData(res?.navn);
          }

          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }, 250);
    search();
  }, [query]);

  return (
    <div className={`newSearch--container ${className}`}>
      <div className="newSearch--inputWrap">
        <input
          type="text"
          placeholder={"Søk etter stedsnavn..."}
          required
          onChange={(e) => onQueryChange(e.target.value)}
          value={query}
        />
        <button className="newSearch--clear-btn" onClick={onClearSearch}>
          {clearButtonIcon}
        </button>
      </div>
      {query && (
        <div className="newSearch--results-wrapper">
          {loading && (
            <div className="newSearch--loadingElem">
              <LoadingIndicator />
            </div>
          )}

          <SearchResultComponent
            results={data}
            onResultSelected={onResultSelected}
            query={query}
          />
        </div>
      )}
    </div>
  );
};

export default Search;
