const getPath = (location) => {
  return location.pathname.length > 1 ? location.pathname.slice(1) : "index";
};

const fixLinks = (history) => {
  let pageContent = document.getElementsByClassName("tell-tur-cms")[0];
  if (pageContent) {
    let links = pageContent.getElementsByTagName("a");
    [].forEach.call(links, (link) => {
      if (
        link.host !== window.location.host ||
        /\..{3,4}$/.test(link.href) ||
        link.target
      ) {
        return;
      }

      link.onclick = (e) => {
        let nl = link.pathname;
        e.preventDefault();
        if (link.pathname.indexOf("/") !== 0) {
          nl += "/" + nl;
        }
        history.push(nl);
      };
    });
  }
};

export { getPath, fixLinks };
