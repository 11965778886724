/** @format */

// ------------------------------------
// Constants
// ------------------------------------
export const UPDATE_FILTERS = "UPDATE_FILTERS";
const SET_SOURCES = "SET_SOURCES";
const RESET_FILTER = "RESET_FILTER";

export const TURMAL_LAYER = "turmal";
export const FRILUFTSELEMENTER_LAYER = "friluftselementer";
export const VESTKYSTPARKELEMENTER_LAYER = "vestkystparkelementer";
/**export const UTHAVNERELEMENTER_LAYER = "vestkystparkelementer"; // vkpelementer
export const AKTIVITETERELEMENTER_LAYER = "vestkystparkelementer";
export const FASILITETERELEMENTER_LAYER = "fasilitetelementer";
export const OVERNATTINGELEMENTER_LAYER = "overnattingelementer";**/
export const VKP_TILGJENGELIG = "vkp_tilgjengelig";

// ------------------------------------
// Actions
// ------------------------------------
const updateFilter = (key, value) => ({
  type: UPDATE_FILTERS,
  payload: {
    key,
    value,
  },
});

const setSources = (sources) => ({
  type: SET_SOURCES,
  payload: { sources },
});

const resetFilter = () => ({
  type: RESET_FILTER,
  payload: {},
});

// ------------------------------------
// Action creators
// ------------------------------------
export const updateFilterState = (key, value) => {
  return (dispatch) => {
    dispatch(updateFilter(key, value));
  };
};

export const updateActiveSources = (sources) => {
  return (dispatch) => {
    dispatch(setSources(sources));
  };
};

export const resetFilterState = () => {
  return (dispatch) => {
    dispatch(resetFilter());
  };
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  turmal__type: [],
  turmal__season: [],
  turmal__avstandFra: 0,
  turmal__avstandTil: 50,
  activeSources: "",
  friluftselementer__type: [],
  vestkystparkelementer__type: [],
  // hovedkategori__type: [], //
  uthavnelementer__type: [],
  aktivitetelementer__type: [],
  overnattingelementer__type: [],
  fasilitetelementer__type: [],
  vkp__sesong: "",
  vkp__tilgjengelig: false,
  overnatting__type: [],
};

export function filterReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_FILTER: {
      return initialState;
    }
    case SET_SOURCES: {
      const { sources } = action.payload;

      return { ...state, activeSources: sources };
    }
    case UPDATE_FILTERS: {
      const { key, value } = action.payload;
      if (value.length === 0) {
        return initialState;
      }

      if (key === "vkp__sesong") {
        return { ...state, [key]: value };
      } else if (key === "vkp__tilgjengelig") {
        return { ...state, vkp__tilgjengelig: !state.vkp__tilgjengelig };
      } else {
        var ttIdx = state[key].indexOf(value);
        const newFilter = Array.isArray(value)
          ? [...new Set([...state[key], ...value])]
          : [...new Set([...state[key], value])];

        if (key === "vestkystparkelementer__type" && Array.isArray(value)) {
          if (state[key].some((val) => value.includes(val))) {
            return {
              ...state,
              [key]: state.vestkystparkelementer__type.filter(
                (f) => !value.includes(f)
              ),
            };
          }
          return { ...state, [key]: newFilter };
        }

        /** if (key === 'hovedkategori__type' && ttIdx !== -1) {
        newFilter.splice(ttIdx, 1);
        return {
          ...state,
          [key]: newFilter,
          vestkystparkelementer__type: state.vestkystparkelementer__type.filter(
            f => !config[value].map(n => +n.category).includes(f)
          )
        };
      } **/
        if (ttIdx !== -1) {
          newFilter.splice(ttIdx, 1);
        }
        return { ...state, [key]: newFilter };
      }
      // Fix here
    }
    default:
      return state;
  }
}
