import React, { useEffect, useMemo } from "react";
import { useState } from "react";

const SearchResultComponent = ({ results, onResultSelected, query }) => {
  const [showAllText, setShowAllText] = useState("Vis alle treff");
  const [showAll, setShowAll] = useState(false);

  const [handledResults, sethandledResults] = useState(
    results
      ?.filter((result) => {
        if (
          result?.navneobjekttype !== "Adresse" &&
          result?.navneobjekttype !== "Havområde"
        ) {
          return result;
        }
      })
      ?.slice(0, 5)
  );

  useEffect(() => {
    if (showAll) {
      sethandledResults(
        results?.filter((result) => {
          if (
            result?.navneobjekttype !== "Adresse" &&
            result?.navneobjekttype !== "Havområde"
          ) {
            return result;
          }
        })
      );
    } else {
      sethandledResults(
        results
          ?.filter((result) => {
            if (
              result?.navneobjekttype !== "Adresse" &&
              result?.navneobjekttype !== "Havområde"
            ) {
              return result;
            }
          })
          ?.slice(0, 5)
      );
    }
  }, [results, showAll]);

  const mappedResults = useMemo(() => {
    if (handledResults?.length) {
      return handledResults?.map((result, i) => (
        <li
          key={result?.stedsnummer + "_" + i}
          className="newSearch-result"
          onClick={() => onResultSelected(result)}
        >
          <div className="newSeach-result-container">
            {result?.kommuner?.map((kommune) => (
              <p key={kommune.kommunenummer} className="newSearch-name">
                {result?.stedsnavn[0].skrivemåte}, {kommune?.kommunenavn}
              </p>
            ))}
            <p className="newSearch-type">{result?.navneobjekttype}</p>
          </div>
        </li>
      ));
    } else {
      return <p className="no-results">Ingen resultater</p>;
    }
  }, [handledResults, onResultSelected]);

  const showMoreButton = useMemo(() => {
    const HandleResultSwitch = () => {
      setShowAll(!showAll);
      if (showAll) {
        setShowAllText("Vis alle treff");
      } else {
        setShowAllText("Vis færre treff");
      }
    };

    if (query && results && results?.length !== 0) {
      return (
        <button
          onClick={HandleResultSwitch}
          className="pull-right btn btn-link "
        >
          {showAllText}
        </button>
      );
    } else return null;
  }, [query, results, showAll, showAllText]);

  return (
    <>
      {showMoreButton}
      <ul className="newSearch-result-list">{mappedResults}</ul>
    </>
  );
};

export default SearchResultComponent;
