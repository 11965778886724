export default {
  locale: "en",

  MapLayout: {
    locale: "en",
    zoomInTip: "Zoom in",
    zoomOutTip: "Zoom out",
    trackTip: "My location",
    kartlagsVelger: "Select map layers",
    fullscrenTip: "Show map in fullscreen",
    basemapGroup: "Basemap layers",
    maplayerGroup: "Thematic layers",
    seeMore: "See more",
    length: "Length",
    time: "Time",
    season: "Season",
  },

  App: {
    locale: "en",
    zoomInTip: "Zoom in",
    zoomOutTip: "Zoom out",
    trackTip: "My location",
    basemapGroup: "Basemap layers",
  },

  Trips: {
    locale: "en",
    selectTourType: "Select trip type:",
    turType: "Trip type",
    lettFottur: "Short walk",
    langFottur: "Long walk",
    klatretur: "Hiking",
    sykleLangsVei: "Cycling",
    sykleITerreng: "Downhill cycling",
    havpadling: "Kayaking",
    skiturIFjell: "Alpine skiing",
    skiturPaFlatmark: "Cross-country skiing",
    klarForAlt: "Ready for all!",
    findTourOnMap: "... or find trip on map",
    host: "Autumn",
    var: "Spring",
    sommer: "Summer",
    vinter: "Winter",
    barmark: "Dryland",
    snofore: "Snowy roads",
    bade_barmark_og_snofore: "Both drylands and snowy roads",
    alle: "All",
    Skitur: "Ski trips",
    AnnenTur: "Other trips",
    Jakttur: "Hunting trips",
    Padletur: "Kayak/canoe trips",
    Fisketur: "Fishing trips",
    Dykkertur: "Diving trips",
    Sykkeltur: "Biking trips",
    Klatretur: "Climbing trips",
    Aktivitet: "Activity",
    Fottur: "Walking trips",
    Trugetur: "Snowshoes",
  },

  ArticleView: {
    searchForTrip: "Search For trip",
    LoadMore: "Load more",
    filter: "Filters",
  },

  ArticleDetailView: {
    title: "Vestkystparken",
  },

  GodTurArticle: {
    copyLinkBtn: "Copy link",
    copyLink:
      "Copy the link below to share this trip with your friends on chat or email.",
    linkCopied: "Link copied",
    copyLinkCloseBtn: "Close",
    elevation: "Elevation",
    length: "Length",
    points: "Points",
    startDate: "Start date",
    endDate: "End date",
    RentalCabinsNearby: "Rental cabins nearby",
    time: "Time",
    season: "Season",
    ShareTrip: "Share this trip:",
    gpxFile: "GPX-file",
  },

  vkpKategori: {
    0: "Jubileumsomr�de",
    1: "Flytebrygge",
    2: "Kai",
    3: "Badeplass",
    4: "Fort�yningsbolter",
    5: "B�tutsettingsrampe",
    6: "Fort�yningsb�ye",
    7: "Ferskvann-drikkevann",
    9: "Toalett",
    10: "Padletur",
    11: "Fisketur",
    12: "Dykking",
    13: "B�l-grillplass",
    14: "Lekeapparater",
    15: "Klatring",
    17: "Fottur",
    21: "Gapahuk",
    22: "Parkeringsplass",
    23: "Rasteplass-benker-bord",
    24: "Ankring",
  },

  SlideMenu: {
    turfilter: "Trip filter:",
    vanskelihetsgrad: "Difficulty:",
    distanse: "Distance:",
    FilterIntroText:
      "You can turn the display of trips and outdoor elements on and off using the toggle buttons in the main area. When zooming and panning in the map, the selection will change.",
    ShowSuggestions: "Show suggestions",
    ShowOutdoorElements: "Show outdoor elements",
    // ShowOutportElements: 'Show outports',
    ShowVkpElements: "Vis VKP",
    // ShowHytteElements: 'Show cabins and kystledhus',
    chooseOutdoorElementType: "Choose type",
    chooseTripType: "Choose type",
    showFilter: "Show filter",
    hideFilter: "Hide filter",
    menuButtonText: "Filter", //"Klikk for � velge hva som skal vises i kartet",
  },

  RangeInfo: {
    max: "Trips shorter than {max} km.",
    min: "Trips longer than {min} km.",
    range: "Trips longer than {min} km and shorter than {max} km.",
  },

  UserRegistrationPage: {
    UserRegistration: "User registration",
    PasswordsDoNotMatch: "Passwords do not match",
    PasswordIsTooShort: "Password is too short",
    FirstnameNotValid: "First name not valid",
    SurnameNotValid: "Surname not valid",
    MobileNotValid: "Mobile number not valid",
    EmailAddressNotValid: "Email address not valid",
    organizationNotValid: "Organization not valid",
    CancelAndReturnToFrontPage: "Cancel and return to front page",
    ToLogin: "Go to login",
    UserRegistrationIntro:
      "Opening an account at <strong>GodTur</strong> allows you to share trips with others, using images, text and map tools. Your created trips can be managed from your profile page. By submitting this form, you will also get an account at the website <strong>TellTur.no</strong>, where you can enter competitions, compete with other users, and win prizes from your local friluftsråd. The username and password for these two sites will be the same.",
    MandatoryFields: "Form fields marked with * are mandatory.",
    CreatedUser:
      "We have created a new user for you. Click the link to log in.",
  },

  UserRegistrationForm: {
    Register: "Register account",
    Password: "Password*",
    PasswordRepeat: "Repeat the password*",
    Firstname: "First name*",
    Surname: "Surname*",
    Mobile: "Mobile phone number",
    Email: "Email address* (this will be you username when logging in)",
    Organization: "Organization",
    EmailPlaceholder: "Enter your email adress",
    PasswordPlaceholder: "Enter a password",
    PasswordRepeatPlaceholder: "Repeat the password",
    FirstnamePlaceholder: "Enter your first name",
    SurnamePlaceholder: "Enter your surname",
    MobilePlaceholder: "Enter your phone number",
    OrganizationPlaceholder:
      "If you are a member of a GodTur-organization, provide its name here.",
    AcceptPrivacyPolicy:
      "I have read and accept that data will be shared with collaborating trip pages and that my personal data is kept according to the ",
    PrivacyPolicy: "privacy statement",
  },

  LoginPage: {
    LogIn: "Log in",
    Username: "Email",
    LogInIntroText:
      "To share your trips with others you need to be logged in. You can ",
    Password: "Password",
    ForgotPassword: "Forgot your password?",
    RegisterHere: "create a new account here",
    Error:
      "Login failed. Please ensure that your email and password are correct",
  },

  ResetPasswordPage: {
    Email: "Email",
    SendNewPassword: "Send new password",
    Cancel: "Cancel",
    CouldNotResetPassword:
      "Could not reset password. Please make sure that the email address is correct.",
    PasswordResetInfoText:
      "A new password has been sent to your email address. If you can't find it please check your spam-filter",
    GoBack: "Back to login",
  },

  TranslatedServerError: {
    UserExists:
      "A user with this email address already exists. If you have registered a user on godtur.no or telltur.no you can use these credentials to log in",
    InvalidUser: "Invalid user data",
    InvalidOwner:
      "You do not own the object you are trying to change. Contact your administrator.",
    EmailInUse: "This email address is already in use",
    SessionExpired: "Your session has expired. Please log in again.",
  },

  NavBar: {
    MyProfile: "My profile",
    LogOut: "Log out",
    LogIn: "Log in",
    AllTrips: "Show all trips",
    RegisterUser: "Register user",
    SearchPlaceholder: "Hva leter du etter?",
  },

  SearchResultComponent: {
    noResults: "No matches found. Please check the spelling and try again",
  },

  MyTripsList: {
    Title: "Title",
    Added: "Added",
    Tools: "Tools",
    MyTrips: "My trips",
    NoTripsRegistered: "No trips registered",
  },

  MyProfileContainer: {
    MyPage: "My page",
    FirstnameNotValid: "First name not valid",
    SurnameNotValid: "Surname not valid",
    MobileNotValid: "Mobile number not valid",
    EmailAddressNotValid: "Email address not valid",
    OrganizationNotValid: "Organization not valid",
  },

  MyProfileForm: {
    Save: "Save",
    Firstname: "First name",
    Fullname: "Full name",
    Mobile: "Mobile phone number",
    Email: "Email address",
    Organization: "Organization",
    ShowAndEditProfileDetails: "Show and edit profile",
    HideProfileDetails: "Hide profile",
    ConfirmDelete: "I understand, delete my account",
    DeleteAccountHeader: "Delete my GodTur (and TellTur) account",
    DeleteGodTurAccount:
      "You are about to delete your GodTur account. You will no longer be able to log in or edit trips. This action is non-reversible.",
    DeleteTellTurAccount: `Attention: You are also about to delete your TellTur.no user account.
      You need this account if you want to participate in TellTur contests.
      If you delete your account, all information about your user will be permanently deleted,
      including the trips you have registered.`,
    DeleteAccount: "Delete account",
    ChangePassword: "Change password",
  },

  LastPublished: {
    lastPublished: "Newest trips",
    seeAll: "See all",
  },

  Activities: {
    locale: "en-GB",
    AllActivities: "All activities",
  },

  News: {
    locale: "en-GB",
    lesMer: "read more",
    news: "News  archive",
    noNews: "No news found",
  },

  ChangePasswordPage: {
    ChangePassword: "Change password",
    CurrentPassword: "Current password",
    NewPassword: "New password",
    RepeatNewPassword: "Repeat new password",
    Cancel: "Cancel",
    SaveNewPassword: "Save new password",
    PasswordChanged: "Your password was successfully changed",
    GoBack: "Back to your profile",
    CouldNotChangePassword: "Could not change your password",
  },
  GeomForm: {
    locale: "en-GB",
    saveFailed: "Save failed, please try again",
    infoText:
      "Add name and description if you like. The name will be show in map for each point and line you have drawn. Both the name and description will also be added to the GPX-file that may be downloaded for the trip.",
    cancel: "Cancel",
    save: "Save",
    continue: "Continue",
  },

  GodTurArticleCommentForm: {
    GiveRating: "Rate this trip or article:",
    Email: "Email address (will not be displayed)",
    DisplayName: "Name",
    Comment: "Comment",
    Cancel: "Cancel",
    Submit: "Submit",
    EmailIsMandatory: "An email address is mandatory",
    DisplayNameIsMandatory: "A name is mandatory",
    EmailAddressNotValid: "The email address is not valid",
    RatingIsMandatory: "Rating is mandatory",
    RatingIsNotValid: "The rating is not valid",
  },

  GodTurArticleCommentSection: {
    RatingsIntro: "Read what others have to say - and ",
    RatingsLink: "leave your comment!",
  },

  EditTripForm: {
    locale: "en-GB",
    publish_disclaimer:
      "By publishing this trip you accept that the trip will be visible on GodTur and collaborating trip pages. You may withdraw your consent any time by either depublish or delete the trip.",
    publish: "Publish",
    unpublish: "Unpublish",
    mustDoBeforePublish:
      "To publish this trip you are required as a minimum to give the trip a title and a description, upload minimum one picture, specify type and season and draw a line of the trip by using the map.",
    distance: "Distance",
    timeSpent: "Time spent",
    season: "Season",
    type: "Type",
    description: "Description",
    municipality: "Municipality",
    selectMunicipality: "Select municipality...",
    title: "Title",
    backToMyProfile: "Back to my profile",
    editTrip: "Edit trip",
    line: "Line",
    media: "Media",
  },

  MediaField: {
    caption: "Caption",
    dragAndDrop: "Drag and drop or click here for uploading images",
  },

  PrivacyPage: {
    PrivacyStatement: "Privacy statement",
    AcceptPrivacyPolicy:
      "I have read and accept that data will be shared with collaborating trip pages and that my personal data is kept according to the ",
    PrivacyPolicy: "privacy statement",
    Accept: "Accept",
    ConsentError:
      "Something went wrong when registering your accept. Please try again or contact the administrator.",
  },
};
