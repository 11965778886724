import React, { useEffect, useState, useMemo, useCallback } from "react";
import { ensureMap } from "@avinet/react-openlayers";
import { Style, Fill, Stroke } from "ol/style";
import { findIcon } from "../../utils/findIcon";
import { findType } from "../../utils/findType";
import "./HoverFeatures.scss";

export const HoverFeatures = ensureMap(({ map }) => {
  const selectStyle = new Style({
    fill: new Fill({
      color: "#eeeeee",
    }),
    stroke: new Stroke({
      color: "rgba(255, 255, 255, 0.7)",
      width: 2,
    }),
  });

  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [arrowUp, setArrowUp] = useState({ display: "none" });
  const [arrowDown, setArrowDown] = useState({ display: "none" });

  const popupStyle = useMemo(() => {
    if (!selectedFeatures || selectedFeatures.length === 0) {
      return;
    }

    const properties = selectedFeatures[0]?.getProperties();

    if (properties?.features === null || properties?.features === undefined) {
      return;
    }

    const flatcoordinates =
      properties?.features[0]?.values_?.geometry?.flatCoordinates;

    const pixel = map.getPixelFromCoordinate(flatcoordinates);

    if (pixel[1] < 80) {
      setArrowDown({ display: "none" });
      setArrowUp({ display: "visible" });
      return { top: `${pixel[1] + 155}px`, left: `${pixel[0]}px` };
    }
    setArrowDown({ display: "visible" });
    setArrowUp({ display: "none" });
    return { top: `${pixel[1] + 65}px`, left: `${pixel[0]}px` };
  }, [map, selectedFeatures]);

  const getSymbol = useMemo(() => {
    if (!selectedFeatures || selectedFeatures.length === 0) {
      return;
    }

    const properties = selectedFeatures[0]?.getProperties();

    if (properties?.features === null || properties?.features === undefined) {
      return;
    }

    return findIcon(properties?.features[0]?.values_?.symbol_id);
  }, [selectedFeatures]);

  const getType = useMemo(() => {
    if (!selectedFeatures || selectedFeatures.length === 0) {
      return;
    }

    if (
      (selectedFeatures?.length > 1 &&
        selectedFeatures?.[0].values_?.features?.[0]?.values_?.id ===
          selectedFeatures?.[1]?.values?.id) ||
      selectedFeatures?.[0]?.values_?.features?.length > 1
    ) {
      return "Flere valgt";
    }

    const properties = selectedFeatures[0]?.getProperties();

    if (properties?.features === null || properties?.features === undefined) {
      return;
    }

    return findType(properties?.features[0]?.values_?.symbol_id);
  }, [selectedFeatures]);

  useEffect(() => {
    const onHover = (e) => {
      if (selectedFeatures && selectedFeatures.length > 0) {
        setSelectedFeatures([]);

        map.getTargetElement().style.cursor = "auto";
      }

      const currentFeatures = map.getFeaturesAtPixel(e.pixel, {
        hitTolearance: 20,
      });
      setSelectedFeatures(currentFeatures ? currentFeatures : []);

      if (currentFeatures && currentFeatures.length > 0) {
        map.getTargetElement().style.cursor = "pointer";
      }
    };

    if (map) {
      map.on("pointermove", onHover);
    }

    return () => map.un("pointermove", onHover);
  }, [map, selectStyle, selectedFeatures, setSelectedFeatures]);

  if (!selectedFeatures || selectedFeatures.length === 0) {
    return null;
  }

  return (
    <div id="popup" className="map-hover-select-container" style={popupStyle}>
      <div className="arrow-up" style={arrowUp}></div>
      {getSymbol ? (
        <div className="content-box">
          <img src={getSymbol} alt="icon" />
          <span className="type-text">{getType}</span>{" "}
        </div>
      ) : null}
      <div className="arrow-down" style={arrowDown}></div>
    </div>
  );
});
