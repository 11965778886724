import flytebrygge from "../static/typeIcons/flytebrygge.svg";
import kai from "../static/typeIcons/kai.svg";
import fortoyningsbolter from "../static/typeIcons/fortoyningsbolter.svg";
import batutsettingsrampe from "../static/typeIcons/batutsettingsrampe.svg";
import fortoyningsboye from "../static/typeIcons/fortoyningsboye.svg";
import ankring from "../static/typeIcons/ankring.svg";
import badeplass from "../static/typeIcons/badeplass.svg";
import padling from "../static/typeIcons/padling.svg";
import fiske from "../static/typeIcons/fiske.svg";
import baalOgGrill from "../static/typeIcons/baalOgGrill.svg";
import lek from "../static/typeIcons/lek.svg";
import lekeplass from "../static/typeIcons/lekeplass.svg";
import klatring from "../static/typeIcons/klatrefelt.svg";
import fottur from "../static/typeIcons/fottur.svg";
import ferskvann from "../static/typeIcons/ferskvann.svg";
import basseng from "../static/typeIcons/basseng.svg";
import toalett from "../static/typeIcons/toalett.svg";
import gapahuk from "../static/typeIcons/gapahuk.svg";
import parkeringsplass from "../static/typeIcons/parkeringsplass.svg";
import friluftsomrade from "../static/typeIcons/friluftsomrade.svg";
import rasteplass from "../static/typeIcons/rasteplass.svg";
import utedo from "../static/typeIcons/utedo.svg";
import stupebrett from "../static/typeIcons/stupebrett.svg";
import utsettingKanoKajakk from "../static/typeIcons/utsettingKanoKajakk.svg";
import grill from "../static/typeIcons/grill.svg";
import aktivitetsanlegg from "../static/typeIcons/aktivitetsanlegg.svg";
import ballokke from "../static/typeIcons/ballokke.svg";
import hyttemer from "../static/typeIcons/hyttemer.svg";
import hytte from "../static/typeIcons/hytte.svg";
import teltplass from "../static/typeIcons/teltplass.svg";
import sykkeltur from "../static/typeIcons/sykling.svg";
import badeflaate from "../static/typeIcons/badeflaate.png";
import ladestasjon from "../static/typeIcons/ladestasjon.svg";

export function findIcon(symbol) {
  switch (symbol) {
    case 4:
      return badeflaate;
    case 5:
      return badeplass;
    case 6:
      return badeplass;
    case 8:
      return ballokke;
    case 9:
      return basseng;
    case 13:
      return baalOgGrill;
    case 14:
      return batutsettingsrampe;
    case 17:
      return fottur;
    case 18:
      return utsettingKanoKajakk;
    case 20:
      return ferskvann;
    case 21:
      return fiske;
    case 22:
      return flytebrygge;
    case 24:
      return fortoyningsbolter;
    case 25:
      return fortoyningsboye;
    case 29:
      return gapahuk;
    case 33:
      return grill;
    case 37:
      return aktivitetsanlegg;
    case 40:
      return kai;
    case 42:
      return klatring;
    case 47:
      return lek;
    case 48:
      return lekeplass;
    case 54:
      return ladestasjon;
    case 55:
      return parkeringsplass;
    case 56:
      return rasteplass;
    case 66:
      return stupebrett;
    case 67:
      return teltplass;
    case 68:
      return toalett;
    case 70:
      return utedo;
    case 71:
      return ankring;
    case 277:
      return padling;
    case 281:
      return sykkeltur;
    case 282:
      return fottur;
    case 111:
      return hytte;
    case 555:
    case 444:
      return hyttemer;
    default:
      return friluftsomrade;
  }
}
