import React from "react";
import PropTypes from "prop-types";
import NavBar from "../containers/NavBar";
import Footer from "../components/Footer/Footer";
import "./IndexLayout.scss";

class IndexLayout extends React.Component {
  componentDidMount(prevProps) {
    window.scrollTo(0, 0);
    document
      .getElementsByClassName("react-openlayers--map")[0]
      .classList.add("hide-map");
  }

  render() {
    const { children } = this.props;
    return (
      <div className="index-layout--root">
        <NavBar />
        <div className="index-layout--container">
          <div className="index-layout--components">{children}</div>
        </div>
        <Footer />
      </div>
    );
  }
}

IndexLayout.propTypes = {
  children: PropTypes.element,
};

export default IndexLayout;
