import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import LoadingIndicator from "./LoadingIndicator";
import config from "../config/index";
import "./SingleCarousel.scss";

const SingleCarousel = ({
  mainImage,
  images,
  loading,
  externalUrl,
  isFlickr,
}) => {
  var sliderImages = [];
  if (mainImage) {
    if (externalUrl) {
      sliderImages = sliderImages.concat([mainImage]);
    } else {
      const uuid = mainImage
        .substring(mainImage.indexOf("uuid=") + 5)
        .split("&")[0];
      sliderImages = images.filter((i) => i.uuid !== uuid);
      sliderImages.unshift(images.find((i) => i.uuid === uuid));
    }
  } else {
    sliderImages = sliderImages.concat(images);
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const sliderContents = sliderImages.map((i) => {
    if (i === undefined) {
      return null;
    }

    return (
      <div className="slider-image--container" key={i.uuid || externalUrl}>
        {externalUrl ? (
          isFlickr ? (
            <a href={externalUrl} target="_blank" rel="noopener noreferrer">
              <img src={i} alt="" />
              <div className="slider-image--caption">
                <span>Klikk for flere bilder p� Flickr</span>
              </div>
            </a>
          ) : (
            <img src={i} alt="" />
          )
        ) : (
          <React.Fragment>
            <img
              src={
                config.adaptiveUrl +
                "WebServices/generic/Media.asmx/Download?uuid=" +
                i.uuid +
                "&thumbnail_size=medium"
              }
              alt=""
            />
            {i.comment && (
              <div className="slider-image--caption">
                <span>{i.comment}</span>
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    );
  });

  return (
    <div className="single-carousel">
      {loading ? (
        <LoadingIndicator />
      ) : (
        <Slider {...settings}>{sliderContents}</Slider>
      )}
    </div>
  );
};

SingleCarousel.propTypes = {
  mainImage: PropTypes.string,
  images: PropTypes.array,
  loading: PropTypes.bool,
  flickrUrl: PropTypes.string,
  isFlickr: PropTypes.bool,
  externalUrl: PropTypes.string,
};

export default SingleCarousel;
