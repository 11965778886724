import React from "react";
import PropTypes from "prop-types";
import NavBar from "../containers/NavBar";
import {
  WmsLayer,
  provideMapState,
  LayerSwitch,
  LayerGroup,
  Zoom,
  olUtils,
  XYZLayer,
} from "@avinet/react-openlayers";
import { provideViewSize } from "../hocs/provideViewSize";
import InitExtentButton from "../components/InitExtentButton";
import PopupBox from "../components/PopupBox";
import { translate } from "react-translate";
import Search from "../containers/Search/Search";
import { withRouter } from "react-router-dom";
import Icon from "../components/Icon";
import "./MapLayout.scss";
import MapOverlay from "../components/MapOverlay";
import FeaturePopup from "../components/FeaturePopup";
import { HoverFeatures } from "../components/Map/HoverFeatures";

class MapLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fullscreen: false,
      searchOpen: true,
      showTipBox: true,
      tmpHideTipBox: false,
    };
    this.onFullScreenBtnClick = this.onFullScreenBtnClick.bind(this);
    this.onResultSelected = this.onResultSelected.bind(this);
    this.onTipBoxVisibilityChange = this.onTipBoxVisibilityChange.bind(this);
    this.changePopupClass = this.changePopupClass.bind(this);
  }

  componentWillMount() {
    const tipBoxDeactivated = localStorage.getItem("vkp_tipboxdeactivated");
    const tipboxTimestamp = localStorage.getItem("vkp_tipboxtimestamp");

    if (tipBoxDeactivated) {
      this.setState({ showTipBox: false });
    }

    if (tipboxTimestamp) {
      const diff = Math.abs(Date.now() - tipBoxDeactivated);
      const diffDays = Math.floor(diff / (1000 * 3600 * 24));
      if (diffDays > 0) {
        this.setState({ tmpHideTipBox: true });
      }
    }
  }

  componentDidMount() {
    document
      .getElementsByClassName("react-openlayers--map")[0]
      .classList.remove("hide-map");
  }

  componentDidUpdate() {
    const { fullscreen } = this.state;

    if (fullscreen) {
      document
        .getElementsByClassName("react-openlayers--map")[0]
        .classList.add("map-fullscreen");
    } else {
      document
        .getElementsByClassName("react-openlayers--map")[0]
        .classList.remove("map-fullscreen");
    }
    this.props.forceUpdateMap();
  }

  async onResultSelected(result) {
    const { fitViewAndZoom } = this.props;

    if (!result?.representasjonspunkt) {
      return;
    }

    const north = result.representasjonspunkt.nord;
    const east = result.representasjonspunkt.øst;

    if (north && east && result?.navneobjekttype === "Kommune") {
      fitViewAndZoom([east, north, east, north], 12);
    } else if (north && east && result?.navneobjekttype === "Fylke") {
      fitViewAndZoom([east, north, east, north], 11);
    } else if (north && east) {
      fitViewAndZoom([east, north, east, north], 15);
    }

    return;
  }

  onFullScreenBtnClick() {
    const { fullscreen } = this.state;
    this.setState({ fullscreen: !fullscreen });
  }

  onTipBoxVisibilityChange(neverShowAgain) {
    if (neverShowAgain) {
      localStorage.setItem("vkp_tipboxdeactivated", true);
    } else {
      localStorage.removeItem("vkp_tipboxdeactivated");
      localStorage.setItem("vkp_tipboxtimestamp", Date.now());
    }
    this.setState({ showTipBox: !this.state.showTipBox });
  }

  changePopupClass(className) {
    this.setState({ popupClass: className });
  }

  render() {
    const {
      children,
      t,
      selectedFeature,
      articles,
      fitViewAndZoom,
    } = this.props;
    const { searchOpen, showTipBox, tmpHideTipBox, popupClass } = this.state;
    const checkBoxChecked = localStorage.getItem("vkp_tipboxdeactivated");
    const mobileLayout = window.innerWidth < 700;
    const coords =
      selectedFeature &&
      olUtils.getCenterFromGeom(selectedFeature?.getGeometry());

    return (
      <div className="map-layout--root">
        <div className="popup-box-container">
          {showTipBox && !tmpHideTipBox && (
            <PopupBox
              onClose={this.onTipBoxVisibilityChange}
              closeBtnText={t("closeTipBox")}
              checkBoxText={t("dontshowTipBox")}
              checkBoxChecked={!!checkBoxChecked}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: t("tipboxContent"),
                }}
              />
            </PopupBox>
          )}
          <div className="buttons">
            <button
              className="map-layout--tip-btn"
              onClick={() =>
                this.setState({ showTipBox: true, tmpHideTipBox: false })
              }
            >
              <Icon name="info" />
            </button>
          </div>
        </div>
        <NavBar fitViewAndZoom={fitViewAndZoom} />
        <div className={["map-layout--search", "active"].join(" ")}>
          <button className="map-layout--search-button" onClick={() => {}}>
            <Icon name={"search"} />
          </button>
          <Search
            onResultSelected={this.onResultSelected}
            placeholder={t("SearchPlaceholder")}
            isOpen={searchOpen}
            clearButtonIcon={<Icon name="cross" />}
          />
        </div>
        <InitExtentButton />
        <Zoom
          zoomInTipLabel={t("zoomInTip")}
          zoomOutTipLabel={t("zoomOutTip")}
        />
        <LayerSwitch tooltip={t("kartlagsVelger")}>
          <LayerGroup
            id="basemap_group"
            enabledLayers={["norgeskart"]}
            name={t("basemapGroup")}
            activeIcon={<Icon name="radioChecked" />}
            inactiveIcon={<Icon name="radioUnchecked" />}
            singleSelect
          >
            <XYZLayer
              id="norgeskart"
              name="Norgeskart"
              key="norgeskart"
              url="https://cache.kartverket.no/v1/wmts/1.0.0/topo/default/webmercator/{z}/{y}/{x}.png"
              zIndex={-2}
            />

            <XYZLayer
              id="nib"
              key="nib"
              name="Flyfoto"
              url="https://gatekeeper1.geonorge.no/BaatGatekeeper/gk/gk.nib_web_mercator_wmts_v2?REQUEST=GetTile&STYLE=default&TILEMATRIXSET=default028mm&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}"
              zIndex={-2}
              useBaat
            />
          </LayerGroup>
        </LayerSwitch>

        <WmsLayer
          key="hsukart"
          name="hsukart"
          id="hsukart"
          layerName={["ocean_3857"].join(",")}
          version="1.3.0"
          uri="http://map-adaptive3.avinet.no/mapserv.ashx?map=public\world&color=219%20255%20254"
          singleTile
          zIndex={-6}
          useBaat
        />
        {!selectedFeature && <HoverFeatures />}
        {selectedFeature &&
          (mobileLayout ? (
            <FeaturePopup
              selectedFeature={selectedFeature}
              articles={articles}
              history={this.props.history}
              onArticleClose={this.props.onArticleClose}
              t={t}
              popupClass={popupClass}
            />
          ) : (
            <MapOverlay
              coords={coords}
              selectedFeature={selectedFeature}
              changePopupClass={this.changePopupClass}
            >
              <FeaturePopup
                selectedFeature={selectedFeature}
                articles={articles}
                history={this.props.history}
                onArticleClose={this.props.onArticleClose}
                t={t}
                popupClass={popupClass}
              />
            </MapOverlay>
          ))}
        <div>
          <div className="map-layout--components">{children}</div>
        </div>
      </div>
    );
  }
}

MapLayout.propTypes = {
  children: PropTypes.element,
  t: PropTypes.func.isRequired,
  forceUpdateMap: PropTypes.func.isRequired,
  viewWidth: PropTypes.number,
  viewHeight: PropTypes.number,
  history: PropTypes.object,
};

export default provideViewSize(
  provideMapState(withRouter(translate("MapLayout")(MapLayout)))
);
