import { readFiltered } from "../utils/DigiTheme";
import config from "../config/index";

const columns = ["id", "title", "type", "date", "county", "municipality"];

// ------------------------------------
// Constants
// ------------------------------------

export const READING_ACTIVITIES = "READING_ACTIVITIES";
export const ACTIVITIES_READ = "READ_ACTIVITIES";

// ------------------------------------
// Actions
// ------------------------------------

const readActivities = () => ({ type: READING_ACTIVITIES });

const activitiesRead = (error, activities) => {
  return {
    type: ACTIVITIES_READ,
    payload: {
      error,
      activities,
    },
  };
};

// ------------------------------------
// Action creators
// ------------------------------------

export function getActivities() {
  return (dispatch) => {
    dispatch(readActivities());

    const filter = {
      filterColumns: [],
      sortColumns: [
        {
          name: "date",
        },
      ],
      secondFilter: [],
      defaultFilter: [],
      distinctColumns: [],
    };
    readFiltered(
      config.activitiesDigiThemeUuid,
      columns,
      filter,
      (err, response) => {
        if (err || !response.ok) {
          dispatch(
            activitiesRead(
              err || { message: "Received an error from server" },
              undefined
            )
          );
          return;
        }
        response = response.body.d;

        if (response.exception || !response.success) {
          dispatch(
            activitiesRead(
              response.exception || {
                message: "Received an error from server",
              },
              undefined
            )
          );
          return;
        }

        dispatch(activitiesRead(undefined, response.records));
      }
    );
  };
}

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  activities: [],
  loading: false,
};

export function activityReducer(state = initialState, action) {
  switch (action.type) {
    case READING_ACTIVITIES:
      return Object.assign({}, state, { loading: true });
    case ACTIVITIES_READ:
      return Object.assign({}, state, {
        activities: action.payload.activities,
        loading: false,
      });
    default:
      return state;
  }
}
