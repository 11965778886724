import React, { useMemo } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import Icon from "./Icon";
import request from "superagent";
import config from "../config";
import "./ImageCarousel.scss";

const ImageCarousel = ({ record, small = false }) => {
  const ref = useRef();
  const [scrollPos, setScrollPos] = useState(0);
  const [imageUuids, setImageUuids] = useState([]);

  useEffect(() => {
    const recordUuid = record.properties.uuid;
    const featuredImage = record.properties.bilde_multimedia;

    request
      .get(config.adaptiveUrl + "api/fl/vkp/" + recordUuid)
      .then((res) => res.body.map((image) => image.uuid))
      .then((resImages) => {
        setImageUuids(() => {
          if (featuredImage) {
            const resImageUuids = resImages.filter(
              (img) => featuredImage !== img
            );

            return [featuredImage, ...resImageUuids];
          } else {
            return [...resImages];
          }
        });
      })
      .catch((e) => {
        console.error(e);
      });

    return () => setImageUuids([]);
  }, [record.properties.uuid, record.properties.bilde_multimedia]);

  const images = useMemo(
    () =>
      imageUuids.map(
        (uuid) =>
          config.adaptiveUrl +
          "WebServices/generic/Media.asmx/Download?uuid=" +
          uuid
      ),
    [imageUuids]
  );

  const handleClick = useCallback(
    (e) => {
      const scrollValue = parseInt(e.currentTarget.value);
      const width = ref.current.offsetWidth;
      const newValue = scrollPos + scrollValue;

      if (newValue < 0 || newValue >= images.length) return scrollPos;

      const scrollTo = newValue * width;
      ref.current.scrollTo(scrollTo, 0);
    },
    [images, scrollPos, ref]
  );

  useEffect(() => {
    const width = ref.current.offsetWidth;

    function debounce(fn, ms) {
      let timer;
      return (_) => {
        clearTimeout(timer);
        timer = setTimeout((_) => {
          timer = null;
          fn.apply(this, arguments);
        }, ms);
      };
    }

    const handleScroll = debounce(() => {
      const refScrollPos = Math.round(ref.current.scrollLeft);
      const elementScrollState = Math.round(refScrollPos / width);
      setScrollPos(elementScrollState);
    }, 100);

    const carousel = ref.current;

    carousel.addEventListener("scroll", handleScroll, true);
    return () => carousel.removeEventListener("scroll", handleScroll, true);
  }, [ref]);

  const indicatorsRef = useRef();

  useEffect(() => {
    const width = indicatorsRef.current.offsetWidth;
    const scrollTo = (width / 5) * scrollPos - width / 2 + 10;
    indicatorsRef.current.scrollTo(scrollTo, 0);
  }, [scrollPos]);

  return (
    <div className={"image-carousel " + (small ? "small" : "")}>
      {images.length > 1 && (
        <>
          <button
            className={
              "navigate-arrow left " + (scrollPos < 1 ? "unactive" : "active")
            }
            value={-1}
            type="button"
            onClick={handleClick}
          >
            <Icon name="chevronLeft" />
          </button>
          <button
            className={
              "navigate-arrow right " +
              (scrollPos === images.length - 1 ? "unactive" : "active")
            }
            value={1}
            type="button"
            onClick={handleClick}
          >
            <Icon name="chevronRight" />
          </button>
        </>
      )}
      <div className="image-carousel-frame" ref={ref}>
        {images.map((image, i) => (
          <img key={i} src={image} alt={`bilde-${i}`} />
        ))}
      </div>
      <div className="image-indicators" ref={indicatorsRef}>
        {images.length > 1 &&
          images.map((image, i) => (
            <span
              key={i}
              className={
                scrollPos === i
                  ? "active"
                  : scrollPos < i - 1
                  ? "small"
                  : scrollPos > i + 1
                  ? "small"
                  : ""
              }
            />
          ))}
      </div>
    </div>
  );
};

export default ImageCarousel;
