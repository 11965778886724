/** @format */

import React from "react";
import PropTypes from "prop-types";
import SourceSelector from "../components/SourceSelector";
import Icon from "../components/Icon";
import { withRouter } from "react-router-dom";
import { translate } from "react-translate";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { updateFilterState, resetFilterState } from "../redux/filterReducer";
import Switch from "../components/Switch";
import Handicap from "../static/handicap_sign.svg";
import { provideViewSize } from "../hocs/provideViewSize";
import config from "../config/index";

import "react-input-range/lib/css/index.css";
import "./SlideMenu.scss";

const mapDispatchToProps = (dispatch) => {
  return {
    updateFilterState: bindActionCreators(updateFilterState, dispatch),
    resetFilterState: bindActionCreators(resetFilterState, dispatch),
  };
};

const mapStateToProps = (reducers) => ({
  turmalType: reducers.filterReducer.turmal__type,
  avstandFra: reducers.filterReducer.turmal__avstandFra,
  avstandTil: reducers.filterReducer.turmal__avstandTil,
  activeLayers: reducers.filterReducer.activeLayers,
  friluftselementerType: reducers.filterReducer.friluftselementer__type,
  vestkystparkelementerType: reducers.filterReducer.vestkystparkelementer__type,
  vkpSesong: reducers.filterReducer.vkp__sesong,
  vkpTilgjengelig: reducers.filterReducer.vkp__tilgjengelig,
});

class SlideMenu extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: { min: 0, max: 50 },
      slideMenuOpen: false,
      showButtons: false,
      // TODO: Change names
      showDetailsTurforslag: false,
      showDetailsFriluftselementer: false,
      showDetailsVkpelementer: false,
      showDetailsuthavnerelementer: false,
      showDetailaktiviteterelementer: false,
      showDetailsovernattingelementer: false,
      showDetailsfasiliteterelementer: false,
      showTilgjengeligelementer: false,
    };
    this.toggleTypeSource = this.toggleTypeSource.bind(this);
    this.extractLayerNameFromId = this.extractLayerNameFromId.bind(this);
    this.checkIfTypeIsActive = this.checkIfTypeIsActive.bind(this);
    this.handleTilgjengeligClick = this.handleTilgjengeligClick.bind(this);
    this.toggleAllSources = this.toggleAllSources.bind(this);
  }

  componentDidMount() {
    const { slideMenuOpen } = this.state;
    const { viewWidth } = this.props;

    if (viewWidth > 768) {
      this.setState({ slideMenuOpen: !slideMenuOpen });
    }
    this.updateQuery();
    this.updateFilterFromUrl();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.updateQuery();
    }
  }

  updateFilterFromUrl() {
    let {
      location: { search },
      updateFilterState,
      resetFilterState,
    } = this.props;
    search = search.slice(1);
    var queries = search.split("&");
    if (search.length) {
      resetFilterState();
    }
    queries.forEach((q) => {
      var tmp = q.split("=");
      if (tmp[0] && tmp[1]) {
        if (tmp[0].toLowerCase() === "turmal__type") {
          updateFilterState(tmp[0], tmp[1].split(","));
        } else if (tmp[0].toLowerCase() === "turmal__avstandtil") {
          updateFilterState(tmp[0], tmp[1].split(","));
        } else if (tmp[0].toLowerCase() === "turmal__avstandfra") {
          updateFilterState(tmp[0], tmp[1].split(","));
        } else if (tmp[0].toLowerCase() === "friluftselementer__type") {
          updateFilterState(tmp[0], tmp[1].split(","));
        } else if (tmp[0].toLowerCase() === "vestkystparkelementer__type") {
          updateFilterState(
            tmp[0],
            tmp[1].split(",").map((k) => +k)
          );
        }
      }
    });
  }

  checkIfTypeIsActive(id, vestkystparkelementerType) {
    switch (id) {
      case "uthavner":
        // 1,2,4,5,6,24
        if (
          vestkystparkelementerType.indexOf(1) > -1 ||
          vestkystparkelementerType.indexOf(2) > -1 ||
          vestkystparkelementerType.indexOf(4) > -1 ||
          vestkystparkelementerType.indexOf(5) > -1 ||
          vestkystparkelementerType.indexOf(6) > -1 ||
          vestkystparkelementerType.indexOf(24) > -1
        ) {
          return true;
        }
        return false;
      case "aktiviteter":
        // 3,10,11,12,16,15,14,13,17
        if (
          vestkystparkelementerType.indexOf(3) > -1 ||
          vestkystparkelementerType.indexOf(10) > -1 ||
          vestkystparkelementerType.indexOf(11) > -1 ||
          vestkystparkelementerType.indexOf(12) > -1 ||
          vestkystparkelementerType.indexOf(15) > -1 ||
          vestkystparkelementerType.indexOf(14) > -1 ||
          vestkystparkelementerType.indexOf(16) > -1 ||
          vestkystparkelementerType.indexOf(13) > -1 ||
          vestkystparkelementerType.indexOf(17) > -1
        ) {
          return true;
        }
        return false;
      case "overnatting":
        // 111,444,555
        if (
          vestkystparkelementerType.indexOf(26) > -1 ||
          vestkystparkelementerType.indexOf(111) > -1 ||
          vestkystparkelementerType.indexOf(444) > -1 ||
          vestkystparkelementerType.indexOf(555) > -1
        ) {
          return true;
        }
        return false;
      case "fasiliteter":
        // 7,8,9,23,22,21
        if (
          vestkystparkelementerType.indexOf(7) > -1 ||
          vestkystparkelementerType.indexOf(8) > -1 ||
          vestkystparkelementerType.indexOf(9) > -1 ||
          vestkystparkelementerType.indexOf(23) > -1 ||
          vestkystparkelementerType.indexOf(22) > -1 ||
          vestkystparkelementerType.indexOf(21) > -1
        ) {
          return true;
        }
        return false;
      default:
        return false;
    }
  }

  updateQuery() {
    const {
      vestkystparkelementerType,
      vkpSesong,
      vkpTilgjengelig,
      history,
      baseUrl,
      activeSources,
    } = this.props;
    let queries = [];

    if (vestkystparkelementerType.length !== 0) {
      queries.push("vestkystparkelementer__type=" + vestkystparkelementerType);
    }
    if (vkpSesong.length !== 0) {
      queries.push("vkp__sesong=" + vkpSesong);
    }
    if (vkpTilgjengelig.length !== 0 && vkpTilgjengelig) {
      queries.push("vkp__tilgjengelig=" + vkpTilgjengelig);
    }
    if (queries.length !== 0) {
      history.push(baseUrl + activeSources + "?" + queries.join("&"));
    } else {
      history.push(baseUrl + activeSources);
    }
  }

  extractLayerNameFromId(id) {
    return id.split("__")[0];
  }

  toggleTypeSource(source, id) {
    const { updateFilterState } = this.props;
    const layer = this.extractLayerNameFromId(id);
    updateFilterState(layer + "__type", source.category);
  }

  toggleAllSources = (elementerType) => {
    const { updateFilterState } = this.props;
    updateFilterState("vestkystparkelementer__type", elementerType);
  };

  handleTilgjengeligClick = (boolean) => {
    if (boolean) {
      const { updateFilterState } = this.props;
      updateFilterState("vkp__tilgjengelig", boolean);
    }
  };

  handleChange = (value) => {
    const { updateFilterState } = this.props;
    updateFilterState("turmal__avstandFra", value.min);
    updateFilterState("turmal__avstandTil", value.max);
  };

  slideMenuHandler = () => {
    const { slideMenuOpen } = this.state;
    this.setState({ slideMenuOpen: !slideMenuOpen });
  };

  render() {
    const { t, vestkystparkelementerType, vkpTilgjengelig } = this.props;
    const {
      slideMenuOpen,
      showDetailsuthavnerelementer,
      showDetailsaktiviteterelementer,
      showDetailsovernattingelementer,
      showDetailsfasiliteterelementer,
    } = this.state;

    const uthavnerelementerIsActive = this.checkIfTypeIsActive(
      "uthavner",
      vestkystparkelementerType
    );
    const aktiviteterelementerIsActive = this.checkIfTypeIsActive(
      "aktiviteter",
      vestkystparkelementerType
    );
    const overnattingelementerIsActive = this.checkIfTypeIsActive(
      "overnatting",
      vestkystparkelementerType
    );
    const fasiliteterelementerIsActive = this.checkIfTypeIsActive(
      "fasiliteter",
      vestkystparkelementerType
    );

    const tilgjengeligIsActive = !!vkpTilgjengelig;

    const uthavnerElementerType = [1, 2, 4, 6, 5, 24];
    const aktiviteterElementerType = [3, 10, 11, 12, 16, 15, 14, 13, 17];
    const overnattingElementerType = [26, 111, 444, 555];
    const fasiliteterElementerType = [7, 8, 9, 23, 22, 21];

    return (
      <div className="slideMenu-general-div">
        <div className="slideMenu--container open">
          <div className="slideMenu--content">
            <div
              className={
                "slideMenu--content-fieldset" +
                (!uthavnerelementerIsActive ? " disabled" : "")
              }
            >
              <Switch
                onChange={() => this.toggleAllSources(uthavnerElementerType)}
                checked={uthavnerelementerIsActive}
              />
              <span className="showSuggestion-title">
                {" "}
                {t("ShowuthavnerElements")}{" "}
              </span>
              <span className="showDetail-icon">
                <span
                  className="showDetails-text"
                  onClick={() =>
                    this.setState({
                      showDetailsuthavnerelementer: !showDetailsuthavnerelementer,
                    })
                  }
                >
                  {t("ShowButtons")}
                </span>
                <button
                  className="detailbutton"
                  onClick={() =>
                    this.setState({
                      showDetailsuthavnerelementer: !showDetailsuthavnerelementer,
                    })
                  }
                  title={slideMenuOpen ? t("hideFilter") : t("showFilter")}
                >
                  {showDetailsuthavnerelementer ? (
                    <Icon name="triangle_up" />
                  ) : (
                    <Icon name="triangle_down" />
                  )}
                </button>
              </span>
            </div>
            {showDetailsuthavnerelementer ? (
              <SourceSelector
                sources={config.uthavner}
                type={vestkystparkelementerType}
                toggleSource={this.toggleTypeSource}
                id="vestkystparkelementer__type"
                displayName={t("chooseOutdoorElementType")}
                stayOpen
                // disabled={!uthavnerelementerIsActive}
              />
            ) : (
              ""
            )}

            <div
              className={
                "slideMenu--content-fieldset" +
                (!aktiviteterelementerIsActive ? " disabled" : "")
              }
            >
              <Switch
                onChange={() => this.toggleAllSources(aktiviteterElementerType)}
                checked={aktiviteterelementerIsActive}
              />
              <span className="showSuggestion-title">
                {" "}
                {t("ShowaktiviteterElements")}{" "}
              </span>
              <span className="showDetail-icon">
                <span
                  className="showDetails-text"
                  onClick={() =>
                    this.setState({
                      showDetailsaktiviteterelementer: !showDetailsaktiviteterelementer,
                    })
                  }
                >
                  {t("ShowButtons")}
                </span>
                <button
                  className="detailbutton"
                  onClick={() =>
                    this.setState({
                      showDetailsaktiviteterelementer: !showDetailsaktiviteterelementer,
                    })
                  }
                  title={slideMenuOpen ? t("hideFilter") : t("showFilter")}
                >
                  {showDetailsaktiviteterelementer ? (
                    <Icon name="triangle_up" />
                  ) : (
                    <Icon name="triangle_down" />
                  )}
                </button>
              </span>
            </div>
            {showDetailsaktiviteterelementer ? (
              <SourceSelector
                sources={config.aktiviteter}
                type={vestkystparkelementerType}
                toggleSource={this.toggleTypeSource}
                id="vestkystparkelementer__type"
                displayName={t("chooseOutdoorElementType")}
                stayOpen
                //   disabled={!aktiviteterelementerIsActive}
              />
            ) : (
              ""
            )}

            <div
              className={
                "slideMenu--content-fieldset" +
                (!overnattingelementerIsActive ? " disabled" : "")
              }
            >
              <Switch
                onChange={() => this.toggleAllSources(overnattingElementerType)}
                checked={overnattingelementerIsActive}
              />
              <span className="showSuggestion-title">
                {" "}
                {t("ShowovernattingElements")}{" "}
              </span>
              <span className="showDetail-icon">
                <span
                  className="showDetails-text"
                  onClick={() =>
                    this.setState({
                      showDetailsovernattingelementer: !showDetailsovernattingelementer,
                    })
                  }
                >
                  {t("ShowButtons")}
                </span>
                <button
                  className="detailbutton"
                  onClick={() =>
                    this.setState({
                      showDetailsovernattingelementer: !showDetailsovernattingelementer,
                    })
                  }
                  title={slideMenuOpen ? t("hideFilter") : t("showFilter")}
                >
                  {showDetailsovernattingelementer ? (
                    <Icon name="triangle_up" />
                  ) : (
                    <Icon name="triangle_down" />
                  )}
                </button>
              </span>
            </div>
            {showDetailsovernattingelementer ? (
              <SourceSelector
                sources={config.overnatting}
                type={vestkystparkelementerType}
                toggleSource={this.toggleTypeSource}
                id="vestkystparkelementer__type"
                displayName={t("chooseOutdoorElementType")}
                stayOpen
                //  disabled={!overnattingelementerIsActive}
              />
            ) : (
              ""
            )}

            <div
              className={
                "slideMenu--content-fieldset" +
                (!fasiliteterelementerIsActive ? " disabled" : "")
              }
            >
              <Switch
                onChange={() => this.toggleAllSources(fasiliteterElementerType)}
                checked={fasiliteterelementerIsActive}
              />
              <span className="showSuggestion-title">
                {" "}
                {t("ShowfasiliteterElements")}{" "}
              </span>
              <span className="showDetail-icon">
                <span
                  className="showDetails-text"
                  onClick={() =>
                    this.setState({
                      showDetailsfasiliteterelementer: !showDetailsfasiliteterelementer,
                    })
                  }
                >
                  {t("ShowButtons")}
                </span>
                <button
                  className="detailbutton"
                  onClick={() =>
                    this.setState({
                      showDetailsfasiliteterelementer: !showDetailsfasiliteterelementer,
                    })
                  }
                  title={slideMenuOpen ? t("hideFilter") : t("showFilter")}
                >
                  {showDetailsfasiliteterelementer ? (
                    <Icon name="triangle_up" />
                  ) : (
                    <Icon name="triangle_down" />
                  )}
                </button>
              </span>
            </div>
            {showDetailsfasiliteterelementer ? (
              <SourceSelector
                sources={config.fasiliteter}
                type={vestkystparkelementerType}
                toggleSource={this.toggleTypeSource}
                id="vestkystparkelementer__type"
                displayName={t("chooseOutdoorElementType")}
                stayOpen
                // disabled={!fasiliteterelementerIsActive}
              />
            ) : (
              ""
            )}

            <Switch
              onChange={() => this.handleTilgjengeligClick(true)}
              checked={tilgjengeligIsActive}
            />
            <span className="showSuggestion-title"> {t("availability")} </span>
            <span>
              <img
                src={Handicap}
                className="handicap-symbol-img"
                alt="handicap symbol"
              />
            </span>
          </div>
        </div>
      </div>
    );
  }
}

SlideMenu.propTypes = {
  t: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  vkpSesong: PropTypes.string.isRequired,
  vkpTilgjengelig: PropTypes.bool.isRequired,
  vestkystparkelementerType: PropTypes.array.isRequired,
  updateFilterState: PropTypes.func.isRequired,
  resetFilterState: PropTypes.func.isRequired,
  viewWidth: PropTypes.number.isRequired,
  history: PropTypes.object,
  baseUrl: PropTypes.string,
  activeSources: PropTypes.string.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(translate("SlideMenu")(provideViewSize(SlideMenu))));
