import React from "react";
import PropTypes from "prop-types";
import Checkbox from "./Checkbox";
import "./PopupBox.scss";

class PopupBox extends React.Component {
  componentWillMount() {
    this.setState({ checked: this.props.checkBoxChecked });
  }

  render() {
    const { onClose, closeBtnText, checkBoxText, children } = this.props;
    return (
      <div className="popup-box--overlay">
        <div className="popup-box--container">
          <div className="popup-box--content">{children}</div>
          <div className="popup-box--btn-container">
            {checkBoxText && (
              <div className="popup-box--checkbox-container">
                <Checkbox
                  onCheckChange={(newChecked) => {
                    this.setState({ checked: newChecked });
                  }}
                  checked={this.state.checked}
                />
                <label>{checkBoxText}</label>
              </div>
            )}
            <button
              onClick={() => onClose(this.state.checked)}
              className="btn popup-box--close-btn"
            >
              {closeBtnText}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

PopupBox.propTypes = {
  children: PropTypes.element,
  onClose: PropTypes.func.isRequired,
  closeBtnText: PropTypes.string.isRequired,
  checkBoxText: PropTypes.string,
  checkBoxChecked: PropTypes.bool,
};

export default PopupBox;
