import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { navActions } from "@avinet/react-adaptive-cms";
import { withRouter } from "react-router-dom";
import Logo from "../static/logo_vestlandskysten.png";
import Link from "../components/Link";
import Nav from "../components/Nav";
import "./NavBar.scss";

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(Object.assign({}, navActions), dispatch);
};

const mapStateToProps = (state) => ({
  nav: state.nav.nav,
});

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navOpen: false,
    };

    this.onHamburgerClick = this.onHamburgerClick.bind(this);
    this.onSeachDone = this.onSeachDone.bind(this);
  }

  onSeachDone = () => {
    this.setState({ navOpen: false });
  };

  componentDidMount() {
    const { loadNav } = this.props;
    loadNav();
  }

  onHamburgerClick() {
    const { navOpen } = this.state;
    this.setState({ navOpen: !navOpen });
  }

  renderCmsLinks(nav) {
    nav = nav.filter(
      (n) => n.menu_uri !== "minside" && n.menu_uri !== "kystled"
    );
    return nav;
  }

  renderMapLinks() {
    return [
      {
        menu_uri: "alleturer/vestkystparkelementer",
        title: "KART",
        uri: "alleturer/vestkystparkelementer",
      },
    ];
  }

  renderBrochuresLinks(nav) {
    nav = nav.filter((n) => n.menu_uri === "kystled");
    return nav;
  }

  render() {
    const { nav } = this.props;
    const { navOpen } = this.state;

    const cmsLinks = this.renderCmsLinks(nav);
    const brochureLinks = this.renderBrochuresLinks(nav);
    const mapLinks = this.renderMapLinks();
    const { fitViewAndZoom } = this.props;

    return (
      <div className="nav-bar--container">
        <Link className="nav-bar--index-link" to="/">
          <img src={Logo} alt="navbar logo" />
        </Link>
        <div className="vestlandskyst--menu">
          <input
            type="checkbox"
            className="nav-bar--hamburger"
            checked={navOpen}
            onChange={this.onHamburgerClick}
          />
          <span />
          <span />
          <span />
          <Nav
            className="nav-bar--map-menu"
            nav={mapLinks}
            onNavClick={this.onHamburgerClick}
            navbarOpen={this.onSeachDone}
            fitViewAndZoom={fitViewAndZoom}
          >
            <React.Fragment>
              <div className="nav-bar--brochure-menu">
                {brochureLinks.map((n) => (
                  <Link
                    key={n.uri}
                    to={n.menu_uri}
                    onClick={() =>
                      this.setState({
                        navOpen: false,
                      })
                    }
                  >
                    {n.title}
                  </Link>
                ))}
              </div>
              <div className="nav-bar--cms-menu">
                {cmsLinks.map((n) => (
                  <Link
                    key={n.uri}
                    to={n.menu_uri}
                    onClick={() =>
                      this.setState({
                        navOpen: false,
                      })
                    }
                  >
                    {n.title}
                  </Link>
                ))}
              </div>
            </React.Fragment>
          </Nav>
        </div>
      </div>
    );
  }
}

NavBar.propTypes = {
  nav: PropTypes.array,
  loadNav: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavBar));
