import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";
import "./SourceSelector.scss";

class SourceSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      didJustSetSource: false,
    };
    this.toggleSource = this.toggleSource.bind(this);
  }

  toggleSource() {
    this.setState({ didJustSetSource: true });
  }

  render() {
    const {
      sources,
      toggleSource,
      type,
      t,
      id,
      stayOpen,
      disabled,
    } = this.props;

    return (
      <div className="source-selector-general-container">
        <div
          className={
            disabled
              ? "source-selector--container disabled"
              : "source-selector--container"
          }
        >
          <div className="source-selector-background">
            {sources.map((source, i) => (
              <button
                key={`menu_item_${i}`}
                className={
                  type.includes(source.category) ? "btn-active" : "btn-inactive"
                }
                onClick={() => {
                  toggleSource(source, id);
                  stayOpen && this.toggleSource();
                }}
              >
                <span>{t(source.category)}</span>
              </button>
            ))}{" "}
          </div>
        </div>
      </div>
    );
  }
}

SourceSelector.propTypes = {
  sources: PropTypes.array.isRequired,
  type: PropTypes.array.isRequired,
  toggleSource: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  id: PropTypes.string,
  stayOpen: PropTypes.bool,
  disabled: PropTypes.bool,
};

SourceSelector.defaultProps = {
  disabled: false,
};

export default translate("vkpKategori")(SourceSelector);
