export default {
  flickrConfig: {
    userId: "49428017@N05",
    flickrApiKey: "c8b844d04f3c50ae271ae8451d89d3d1",
  },
  tripPointsStyle: {
    fillColor: "#f0a055",
    fillOpacity: 100,
    strokeColor: "#a93900",
    strokeWidth: 2,
    strokeOpacity: 100,
    pointRadius: 8,
    labelProperty: "name",
    labelBackgroundFill: "#f0a055",
    labelTextAlign: "left",
    labelPadding: [5, 5, 5, 5],
    labelOffsetX: 20,
    labelOffsetY: 0,
    font: "14px sans-serif",
  },
  tripLinesStyle: {
    strokeColor: "#a93900",
    strokeWidth: 5,
    strokeOpacity: 100,
    lineDash: [4, 8],
    lineDashOffset: 6,
    labelProperty: "name",
    labelBackgroundFill: "#a93900",
    labelTextAlign: "left",
    labelPadding: [5, 5, 5, 5],
    labelOffsetX: 20,
    labelOffsetY: 0,
    font: "14px sans-serif",
  },
  adaptiveUrl: "https://friluftsforvaltning.avinet.no/",
  guiUrl: "https://godtur.no",
  appName: "Vestkystparken",
  gm_lang_code: "nb",
  privacyVersion: 1,

  // DEV:

  // articleModuleUuid: "e171b934-d940-4f61-9e61-2e162b76d462",

  // PROD:

  articleModuleUuid: "672bce43-a978-4e0c-84a5-2cfba33bb412",

  // Alle vestkystparkelementer

  vestkystparkElementerPublic: {
    // DEV:

    // uuid: "16344356-18a2-4b45-a8a7-481ca6d28649",

    // PROD
    uuid: "c59ac705-b54d-44be-9763-bc57a45f60df",

    columns: [
      "id",
      "geom_wkt",
      "type_friluftselement",
      "symbol_id",
      "datakilde",
      "geom_omraade",
      "godtur_id",
    ],
    dataIndex: "vestkystparkelementer",
  },

  mapProjCode: "3857",
  mapProjection: "EPSG:3857",
  minZoom: 5,
  maxZoom: 18,
  initExtent: [
    131675.39390401903,
    7742295.306785431,
    1978393.9972738773,
    9074134.087626342,
  ],

  dtNews: {
    uuid: "2a55de7c-f391-4ae0-b31c-6e17b5732b99",
    columns: ["id", "title", "content", "summary", "media"],
  },

  dtMunicipality: {
    uuid: "494df784-7a9a-4dfe-a278-a777771747ce",
    columns: ["gid", "komm", "navn"],
  },

  dtTrips: {
    uuid: "a654e177-68f1-4fba-82a7-24e14e314dfd",
    listColumns: ["id", "title", "date_created", "published", "name"],
    columns: [
      "id",
      "sesong",
      "tilrettelagt",
      "datakilde",
      "geom_omraade",
      "date_created",
      "date_modified",
      "created_by",
      "modified_by",
      "uuid",
      "media",
      "title",
      "summary",
      "description",
      "type_friluftsliv",
      "season",
      "time",
      "distance",
      "county",
      "municipality",
      "published",
      "published_date",
    ],
  },
  recaptchaSiteKey: "6LdR8mgUAAAAACc4LpOBwL2Xt62QO4FjAlQxUgow",
  dtLine: {
    uuid: "4010f11d-0645-41ee-a600-614dab0fef0f",
    fkColumn: "fk_trips",
    columns: ["id", "name", "description", "fk_trips", "media"],
    geomType: "LineString",
  },
  dtPoint: {
    uuid: "0de9a481-3083-414f-8a40-e3d5cd4ad48b",
    fkColumn: "fk_trips",
    columns: ["id", "title", "name", "description", "fk_trips", "media"],
    geomType: "Point",
  },

  dtFriluft: {
    uuid: "e41169a6-8265-414d-a931-e71eac48517c",
  },
  articleModuleFilters: {
    turmal: {
      published: {
        name: "published",
        value: true,
        comparisonOperator: "=",
        logicalOperator: "AND",
      },
      season: {
        name: "season",
        value: null,
        comparisonOperator: "ANY",
        netType: "string[]",
        logicalOperator: "AND",
      },
      avstandTil: {
        name: "distance",
        value: null,
        comparisonOperator: "<=",
        logicalOperator: "AND",
      },
      avstandFra: {
        name: "distance",
        value: null,
        comparisonOperator: ">=",
        logicalOperator: "AND",
      },
      type: {
        name: "type_friluftselement",
        value: null,
        comparisonOperator: "ANY",
        netType: "string[]",
        logicalOperator: "AND",
      },
    },

    vestkystparkelementer: {
      type: {
        name: "kategori_array",
        netType: "int[]",
        value: null,
        comparisonOperator: "&&",
        logicalOperator: "OR",
      },

      sesong: {
        name: "sesong",
        netType: "String",
        value: null,
        comparisonOperator: "=",
        logicalOperator: "AND",
      },

      tilgjengelig: {
        name: "tilrettelagt",
        netType: "boolean",
        value: true,
        comparisonOperator: "=",
        logicalOperator: "AND",
      },

      name: "uthavner",
      netType: "boolean",
      value: true,
      comparisonOperator: "=",
      logicalOperator: "OR",
    },
    aktiviteter: {
      name: "aktiviteter",
      netType: "boolean",
      value: true,
      comparisonOperator: "=",
      logicalOperator: "OR",
    },
    fasiliteter: {
      name: "fasiliteter",
      netType: "boolean",
      value: true,
      comparisonOperator: "=",
      logicalOperator: "OR",
    },
    overnatting: {
      name: "overnatting",
      netType: "boolean",
      value: true,
      comparisonOperator: "=",
      logicalOperator: "OR",

      is_kystledhus: {
        name: "is_kystledhus",
        netType: "boolean",
        value: true,
        comparisonOperator: "=",
        logicalOperator: "OR",
      },

      is_utleiehytte: {
        name: "is_utleiehytte",
        netType: "boolean",
        value: true,
        comparisonOperator: "=",
        logicalOperator: "OR",
      },

      is_aapen_hytte: {
        name: "is_aapen_hytte",
        netType: "boolean",
        value: true,
        comparisonOperator: "=",
        logicalOperator: "OR",
      },
    },
  },

  typeFilter: [
    {
      title: "Fisketur",
      uri: "Fisketur",
      icon: "fiskeTur",
    },
    {
      title: "Fottur",
      uri: "Fottur",
      icon: "fotTur",
    },
    {
      title: "Padletur",
      uri: "Padletur",
      icon: "padleTur",
    },
    {
      title: "Skitur",
      uri: "Skitur",
      icon: "skiTur",
    },
    {
      title: "Sykkeltur",
      uri: "Sykkeltur",
      icon: "sykkelTur",
    },
    {
      title: "AnnenTur",
      uri: "Annen_tur",
      icon: "annenTur",
    },
  ],

  vkpTypeFilter: [
    {
      sesongFilter: [
        {
          title: "helaars",
          uri: "helaars",
        },
        {
          title: "vinter",
          uri: "vinter",
        },

        {
          title: "sommer",
          uri: "sommer",
        },
      ],
    },
  ],

  vkpCategoryFilter: [
    {
      category: 1,
      title: "Flytebrygge",
      icon: "flytebrygge",
    },
    {
      category: 2,
      title: "Kai",
      icon: "kai",
    },
    {
      category: 4,
      title: "Fortoyningsbolter",
      icon: "fortoyningsbolter",
    },
    {
      category: 5,
      title: "Batutsettingsrampe",
      icon: "batutsettingsrampe",
    },
    {
      category: 6,
      title: "Fortoyningsboye",
      icon: "fortoyningsboye",
    },

    { category: 24, title: "Ankring", icon: "ankring" },
    {
      category: 3,
      title: "Badeplass",
      icon: "badeplass",
    },
    {
      category: 10,
      title: "Padling",
      icon: "padling",
    },
    {
      // Fisketur og fiskeplass fra land
      category: 11,
      title: "Fiske",
      icon: "fiske",
    },
    {
      category: 12,
      title: "Dykking",
      icon: "dykking",
    },

    {
      category: 13,
      title: "Grill & b�l",
      icon: "grillogbaal",
    },

    {
      category: 14,
      title: "Lek",
      icon: "lek",
    },
    { category: 15, title: "Klatring", icon: "klatring" },
    { category: 16, title: "Sykling", icon: "sykling" },
    { category: 17, title: "Fottur", icon: "fottur" },
    {
      category: 0,
      title: "Jubileumsomraade",
      icon: "jubileumsomraade",
    },
    {
      category: 7,
      title: "Ferskvann",
      icon: "ferskvann",
    },
    /** {
      category: 8,
      title: 'Offentlig transport',
      icon: 'offentligtransport'
    }, */
    {
      category: 9,
      title: "Toalett",
      icon: "toalett",
    },
    {
      category: 21,
      title: "Gapahuk",
      icon: "gapahuk",
    },
    {
      category: 22,
      title: "Parkeringsplass",
      icon: "parkeringsplass",
    },
    {
      category: 23,
      title: "Rasteplass",
      icon: "rasteplass",
    },
    {
      category: 111,
      title: "�pen hytte",
      icon: "�pen hytte",
    },
    {
      category: 222,
      title: "Utleiehytte",
      icon: "utleiehytte",
    },
    {
      category: 555,
      title: "Kystledhus",
      icon: "kystledhus",
    },
  ],

  // uthavn

  uthavner: [
    {
      category: 1,
      title: "Flytebrygge",
      uri: "Flytebrygge",
      icon: "Flytebrygge",
    },
    {
      category: 2,
      title: "Kai",
      uri: "Kai",
      icon: "Kai",
    },
    {
      category: 4,
      title: "Fortoyningsbolter",
      uri: "Fortoyningsbolter",
      icon: "Fortoyningsbolter",
    },

    {
      category: 6,
      title: "Fortoyningsboye",
      uri: "Fortoyningsboye",
      icon: "Fortoyningsboye",
    },

    { category: 24, title: "Ankring", uri: "Ankring", icon: "Ankring" },
    {
      category: 5,
      title: "Batutsettingsrampe",
      uri: "Batutsettingsrampe",
      icon: "Batutsettingsrampe",
    },
  ],

  // Aktiviteter

  aktiviteter: [
    {
      category: 3,
      title: "Badeplass",
      uri: "Badeplass",
      icon: "Badeplass",
    },
    {
      category: 14,
      title: "Lek",
      uri: "Lek",
      icon: "Lek",
    },
    {
      category: 10,
      title: "Padling",
      uri: "Padling",
      icon: "Padling",
    },
    {
      // Fisketur og fiskeplass fra land
      category: 11,
      title: "Fiske",
      uri: "Fiske",
      icon: "Fiske",
    },
    {
      category: 12,
      title: "Dykking",
      uri: "Dykking",
      icon: "Dykking",
    },

    { category: 15, title: "klatring", uri: "klatring", icon: "klatring" },
    { category: 16, title: "Sykling", uri: "Sykling", icon: "Sykling" },
    { category: 17, title: "Fottur", uri: "Fottur", icon: "Fottur" },
    {
      category: 13,
      title: "Grill & b�l",
      uri: "Grillogbaal",
      icon: "Grillogbaal",
    },
  ],

  // Overnatting

  overnatting: [
    { category: 26, title: "Teltplass", uri: "Teltplass", icon: "teltplass" },
    {
      category: 111,
      title: "�pen hytte",
      uri: "�pen hytte",
      icon: "�penhytte",
    },
    {
      category: 444,
      title: "Utleiehytte",
      uri: "Utleiehytte",
      icon: "utleiehytte",
    },
    {
      category: 555,
      title: "Kystledhus",
      uri: "Kystledhus",
      icon: "kystledhus",
    },
  ],

  // Fasiliteter

  fasiliteter: [
    {
      category: 23,
      title: "Rasteplass",
      uri: "Rasteplass",
      icon: "Rasteplass",
    },
    {
      category: 21,
      title: "Gapahuk",
      uri: "Gapahuk",
      icon: "Gapahuk",
    },
    {
      category: 9,
      title: "Toalett",
      uri: "Toalett",
      icon: "Toalett",
    },
    {
      category: 7,
      title: "Ferskvann",
      uri: "Ferskvann",
      icon: "Ferskvann",
    },
    {
      category: 22,
      title: "Parkeringsplass",
      uri: "Parkeringsplass",
      icon: "Parkeringsplass",
    },
    {
      category: 8,
      title: "Offentlig transport",
      uri: "Offentligtransport",
      icon: "Offentligtransport",
    },
  ],

  // Update necessary

  friluftselementerFilter: [
    {
      title: "Badeplass",
      uri: "Badeplass",
      icon: "Badeplass",
    },
    {
      title: "Batutsettplass",
      uri: "Batutsettplass",
      icon: "batutsettplass",
    },
    {
      title: "Gapahuk",
      uri: "Gapahuk",
      icon: "gapahuk",
    },
    {
      title: "Havn",
      uri: "Havn",
      icon: "Havn",
    },
    {
      title: "Rasteplass",
      uri: "Rasteplass",
      icon: "Rasteplass",
    },
    {
      title: "TilrettelagtFriluftsomrade",
      uri: "TilrettelagtFriluftsomrade",
      icon: "tilrettelagt",
    },
    {
      title: "Utleiehytte",
      uri: "Utleiehytte",
      icon: "lockedCabin",
    },
    {
      title: "ApentHusvaere",
      uri: "ApentHusvaere",
      icon: "apentHusvaere",
    },
  ],

  seasonFilter: [
    {
      title: "Host",
      uri: "Host",
    },
    {
      title: "Var",
      uri: "Var",
    },
    {
      title: "Sommer",
      uri: "Sommer",
    },
    {
      title: "Vinter",
      uri: "Vinter",
    },
    {
      title: "Alle",
      uri: "Alle",
    },
    {
      title: "Barmark",
      uri: "Barmark",
    },
    {
      title: "Snofore",
      uri: "Snofore",
    },
    {
      title: "Bade_barmark_og_snofore",
      uri: "Bade_barmark_og_snofore",
    },
  ],
  cms_site_id: 2,
};
