import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";
import { Link } from "react-router-dom";
import Img from "../../static/frontpage/padlehuk_skarvoy.png";
import "./BuoyInfo.scss";

class BuoyInfo extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div className="buoy-container">
        <h3 className="title">{t("title")}</h3>
        <span className="flex-wrapper">
          <span className="buoy-info-column image">
            <img src={Img} alt="" />
          </span>
          <span className="buoy-info-column text">
            <p className="description">{t("description")}</p>
            <Link
              to="/alleturer/vestkystparkelementer?vestkystparkelementer__type=26,111,444,555"
              className="btn btn-primary"
            >
              {t("map")}
            </Link>
          </span>
        </span>
      </div>
    );
  }
}

BuoyInfo.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate("BuoyInfo")(BuoyInfo);
