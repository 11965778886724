import React from "react";
import { injectReducer as defaultInjectReducer } from "../redux/reducers";
import PropTypes from "prop-types";

/**
 * Higher order component (HOC) that allows any other component to monitor view
 * state.
 *
 * Provides props viewWidth, viewHeight.
 */
export function withReducer(WrappedComponent) {
  class WithReducer extends React.Component {
    constructor() {
      super();
      this.injectReducer = this.injectReducer.bind(this);
    }

    injectReducer(key, reducer) {
      defaultInjectReducer(this.context.store, { key, reducer });
    }

    render() {
      return (
        <WrappedComponent injectReducer={this.injectReducer} {...this.props} />
      );
    }
  }

  WithReducer.contextTypes = {
    store: PropTypes.object,
  };

  return WithReducer;
}
