import React from "react";
import PropTypes from "prop-types";
import Link from "./Link";
import { translate } from "react-translate";
import KystledLogo from "../static/footer/Kystledlogo.png";
import SkaergaardsLogo from "../static/footer/Logo-Skjaergaardstjenesten.png";
import { withRouter } from "react-router-dom";

const Nav = ({ nav, onNavClick, children }) => {
  return (
    <div className="cms--nav">
      <div className="cms--menu-links">
        {nav &&
          nav.map((n) => (
            <Link
              key={n.uri}
              to={n.menu_uri}
              onClick={() => onNavClick && onNavClick()}
            >
              {n.title}
            </Link>
          ))}
        {children}

        <span className="imgageLink-container">
          <a
            href="https://kystled.no/"
            target="_blank"
            className="imageLink"
            rel="noopener noreferrer"
          >
            <img src={KystledLogo} alt="Kystled-logo" />
          </a>
          <a
            className="imageLink"
            href="https://www.friluftsrad.no/arbeidsomrader/sjo-og-friluftslivet/skjaergardstjeneste"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={SkaergaardsLogo} alt="skaergaard-logo" />
          </a>
        </span>
      </div>
    </div>
  );
};

Nav.propTypes = {
  nav: PropTypes.array,
  onNavClick: PropTypes.func,
  children: PropTypes.element,
  history: PropTypes.object.isRequired,
  navbarOpen: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withRouter(translate("Nav")(Nav));
